import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import SearchResultsPage from './pages/SearchResultsPage';
import PropertyDetailsPage from './pages/PropertyDetailsPage'; // Import the PropertyDetailsPage
import FoxyAISearchBar from './components/FoxyAISearchBar'; // Import the FoxyAISearchBar

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/search-results" element={<SearchResultsPage />} />
      {/* Add the Property Details Page Route */}
      <Route path="/property/:id" element={<PropertyDetailsPage />} />
    </Routes>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}