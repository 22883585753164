import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWithRouter from './App'; // Ensures AppWithRouter handles routing correctly

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AppWithRouter /> {/* AppWithRouter already includes the Router */}
  </React.StrictMode>
);