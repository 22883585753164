import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();

  // Split the current path into breadcrumb parts, ignoring empty segments
  const pathParts = location.pathname.split('/').filter((part) => part);

  return (
    <nav style={{ backgroundColor: '#f8f9fa', padding: '1rem' }}>
      {/* Main Navbar */}
      <ul style={{ listStyle: 'none', display: 'flex', gap: '1rem', margin: '0', padding: '0' }}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/search">Search</Link></li>
      </ul>

      {/* Breadcrumbs */}
      {pathParts.length > 0 && (
        <div style={{ marginTop: '1rem', fontSize: '0.9rem', color: '#6c757d' }}>
          <nav aria-label="breadcrumb">
            <ul style={{ listStyle: 'none', display: 'flex', gap: '0.5rem', padding: '0', margin: '0' }}>
              <li><Link to="/">Home</Link></li>
              {pathParts.map((part, index) => {
                const to = '/' + pathParts.slice(0, index + 1).join('/');
                return (
                  <li key={to}>
                    <span style={{ margin: '0 0.3rem' }}>/</span>
                    <Link to={to}>{decodeURIComponent(part)}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      )}
    </nav>
  );
};

export default Navbar;