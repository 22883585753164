import React, { useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';

function SearchResultsPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const results = location.state?.results || [];
    const filters = location.state?.filters || {};

    useEffect(() => {
        console.log('Location state:', location.state);
        console.log('Results:', results);
        if (results.length === 0) {
            console.warn('No results found, redirecting to home...');
            navigate('/');
        }
    }, [results, navigate]);

    if (results.length === 0) {
        return (
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-bold">No Results</h1>
                <p>You will be redirected to the home page shortly.</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-4">
            <Navbar />

            {/* Breadcrumb Navigation */}
            <nav className="mb-4">
                <ul className="flex text-sm text-gray-600">
                    <li>
                        <Link to="/" className="text-blue-500 hover:underline">Home</Link>
                        <span className="mx-2">/</span>
                    </li>
                    <li>
                        <Link to="/search" className="text-blue-500 hover:underline">Search</Link>
                        <span className="mx-2">/</span>
                    </li>
                    <li className="text-gray-600">Search Results</li>
                </ul>
            </nav>

            <h1 className="text-2xl font-bold mb-4">Search Results</h1>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {results.map((property) => {
                    console.log('Current Property:', property);

                    let listingDetails = property.listingDetails || {};
                    const detailedListingDetails = property.detailedListingDetails || {};
                    const foxyai = property.foxyai || {};

                    // Normalize data if listingDetails is not present
                    if (Object.keys(listingDetails).length === 0) {
                        listingDetails = {
                            price: property.ListPrice || property.price || 'N/A',
                            ListPrice: property.ListPrice || 'N/A',
                            StreetNumber: property.StreetNumber || null,
                            StreetName: property.StreetName || null,
                            StreetSuffix: property.StreetSuffix || null,
                            City: property.City || null,
                            StateOrProvince: property.StateOrProvince || null,
                            BedroomsTotal: property.BedroomsTotal || null,
                            BathroomsTotalInteger: property.BathroomsTotalInteger || null,
                            LotSizeArea: property.LotSizeArea || null,
                            DaysOnMarket: property.DaysOnMarket || null,
                            YearBuilt: property.YearBuilt || null,
                            ListingId: property.ListingId || property.mlsNumber || null,
                            Media: property.Media || []
                        };
                    }

                    const rawPrice = (listingDetails.price && listingDetails.price !== 'N/A')
                        ? listingDetails.price
                        : (detailedListingDetails.ListPrice && detailedListingDetails.ListPrice !== 'N/A'
                            ? detailedListingDetails.ListPrice
                            : null);

                    const displayPrice = (rawPrice && !isNaN(Number(rawPrice)))
                        ? `$${Number(rawPrice).toLocaleString()}`
                        : 'Not Disclosed';

                    const StreetNumber = listingDetails.StreetNumber ?? detailedListingDetails.StreetNumber;
                    const StreetName = listingDetails.StreetName ?? detailedListingDetails.StreetName;
                    const StreetSuffix = listingDetails.StreetSuffix ?? detailedListingDetails.StreetSuffix;
                    const City = listingDetails.City ?? detailedListingDetails.City;
                    const StateOrProvince = listingDetails.StateOrProvince ?? detailedListingDetails.StateOrProvince;
                    const BedroomsTotal = listingDetails.BedroomsTotal ?? detailedListingDetails.BedroomsTotal;
                    const BathroomsTotalInteger = listingDetails.BathroomsTotalInteger ?? detailedListingDetails.BathroomsTotalInteger;
                    const LotSizeArea = listingDetails.LotSizeArea ?? detailedListingDetails.LotSizeArea;
                    const DaysOnMarket = listingDetails.DaysOnMarket ?? detailedListingDetails.DaysOnMarket;
                    const YearBuilt = listingDetails.YearBuilt ?? detailedListingDetails.YearBuilt;

                    const Media = listingDetails.Media || detailedListingDetails.Media || [];
                    const associatedImages = detailedListingDetails.associatedImages || [];

                    // Address handling
                    let fullAddress = listingDetails.address && listingDetails.address !== 'N/A'
                        ? listingDetails.address
                        : '';

                    if (!fullAddress) {
                        fullAddress = [StreetNumber, StreetName, StreetSuffix, City, StateOrProvince]
                            .filter(Boolean).join(' ');
                        if (!fullAddress) {
                            if (City && StateOrProvince) {
                                fullAddress = `${City}, ${StateOrProvince}`;
                            } else if (City) {
                                fullAddress = City;
                            }
                        }
                    }

                    const displayAddress = fullAddress || 'Location Not Available';

                    const listingId = listingDetails.ListingId ||
                                      detailedListingDetails.ListingId ||
                                      property.mlsNumber;

                    // Image selection logic:
                    // Priority: foxyai.url -> property.url -> associatedImages -> Media
                    let mainImage = null;
                    if (foxyai.url && foxyai.url !== 'N/A') {
                        // If backend now provides foxyai.url for combined searches, use it first
                        mainImage = foxyai.url;
                    } else if (property.url && property.url !== 'N/A') {
                        // Fall back to property.url if foxyai.url not available
                        mainImage = property.url;
                    } else if (associatedImages.length > 0) {
                        // If no FoxyAI or property.url image, use the first associated image
                        mainImage = associatedImages[0];
                    } else if (Media && Media.length > 0) {
                        // Finally, if nothing else, use the first media image if available
                        const mediaUrls = Media.map(m => m.MediaURL).filter(mUrl => mUrl && mUrl !== 'N/A');
                        if (mediaUrls.length > 0) {
                            mainImage = mediaUrls[0];
                        }
                    }

                    const beds = (BedroomsTotal && !isNaN(Number(BedroomsTotal))) ? Number(BedroomsTotal) : null;
                    const baths = (BathroomsTotalInteger && !isNaN(Number(BathroomsTotalInteger))) ? Number(BathroomsTotalInteger) : null;
                    let bedBathLine = '';
                    if (beds && baths) {
                        bedBathLine = `${beds} Bed | ${baths} Bath`;
                    } else if (beds) {
                        bedBathLine = `${beds} Bed${beds > 1 ? 's' : ''}`;
                    } else if (baths) {
                        bedBathLine = `${baths} Bath${baths > 1 ? 's' : ''}`;
                    }

                    let lotSizeLine = '';
                    if (LotSizeArea && !isNaN(Number(LotSizeArea)) && Number(LotSizeArea) > 0) {
                        lotSizeLine = `${Number(LotSizeArea).toLocaleString()} acres`;
                    }

                    const daysOnMarketLine = (DaysOnMarket && !isNaN(Number(DaysOnMarket))) ? `Days on Market: ${DaysOnMarket}` : '';
                    const yearBuiltLine = (YearBuilt && !isNaN(Number(YearBuilt))) ? `Year Built: ${YearBuilt}` : '';
                    const foxyConfidence = (typeof foxyai.confidence === 'number') ? `FoxyAI Confidence: ${foxyai.confidence.toFixed(3)}` : '';

                    const handleClick = () => {
                        navigate(`/property/${listingId}`, {
                            state: { property: { ...property, listingDetails } },
                        });
                    };

                    return (
                        <div
                            key={listingId}
                            className="p-4 border rounded shadow hover:shadow-lg transition-shadow"
                        >
                            {mainImage && (
                                <img
                                    src={mainImage}
                                    alt={`Property Image for ${listingId}`}
                                    className="w-full h-auto object-cover rounded mb-2 cursor-pointer"
                                    onClick={handleClick}
                                    onError={(e) => (e.target.style.display = 'none')}
                                />
                            )}

                            <div className="mb-4 cursor-pointer" onClick={handleClick}>
                                <h2 className="text-xl font-bold mb-1">{displayPrice}</h2>
                                <h3 className="text-lg font-semibold mb-1">{displayAddress}</h3>
                            </div>

                            <div className="text-sm space-y-1">
                                {bedBathLine && <p>{bedBathLine}</p>}
                                {lotSizeLine && <p>Lot Size: {lotSizeLine}</p>}
                                {daysOnMarketLine && <p>{daysOnMarketLine}</p>}
                                {yearBuiltLine && <p>{yearBuiltLine}</p>}
                                {foxyConfidence && <p>{foxyConfidence}</p>}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SearchResultsPage;