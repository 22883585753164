import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import Navbar from '../components/Navbar';

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialFilters = location.state?.filters || {};
  const initialFoxyaiQuery = location.state?.foxyaiQuery || "";

  const [filters, setFilters] = useState(initialFilters);
  const [foxyaiQuery] = useState(initialFoxyaiQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [eulaAccepted, setEulaAccepted] = useState(true); // Adjust as needed

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const hasFilters = Object.keys(filters).length > 0 && Object.values(filters).some(val => val !== "");
    const hasFoxyQuery = foxyaiQuery.trim().length > 0;

    if (hasFilters || hasFoxyQuery) {
      const requestBody = { filters, foxyaiQuery };
      handleSearch(requestBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once on mount

  const handleSearch = async (requestBody) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${baseUrl}/api/search`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch search results');
      }

      const data = await response.json();
      console.log("Search Results:", data);

      navigate('/search-results', { state: { results: data.results || [], filters: requestBody.filters } });
    } catch (error) {
      console.error('Error fetching search results:', error);
      alert(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Navbar />
      <h1 className="text-2xl font-bold mb-4">Unified Property Search</h1>
      <SearchBar
        filters={filters}
        setFilters={setFilters}
        eulaAccepted={eulaAccepted}
        setEulaAccepted={setEulaAccepted}
        onSearch={(updatedRequestBody) => handleSearch(updatedRequestBody)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SearchPage;