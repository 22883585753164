import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../components/SearchBar';

function HomePage() {
  const [filters, setFilters] = useState({
    City: '',
    PostalCode: '',
    minPrice: '',
    maxPrice: '',
    PropertyType: '',
    LotSizeArea: '',
    DaysOnMarket: '',
    BedroomsTotal: '',
    BathroomsTotalInteger: '',
    squareFeet: '',
    keywords: '',
  });

  const [eulaAccepted, setEulaAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Use environment variable for API base URL
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSearch = async (requestBody) => {
    const { filters: filtersFromSearchBar, foxyaiQuery: foxyQuery } = requestBody;

    if (!eulaAccepted) {
      alert('You must agree to the terms of the license agreement before proceeding.');
      return;
    }

    const updatedFilters = { ...filtersFromSearchBar };
    const finalRequestBody = {
      filters: updatedFilters,
      foxyaiQuery: foxyQuery
    };

    console.log('Performing Search with:', finalRequestBody);

    setIsLoading(true);
    try {
      const response = await fetch(`${baseUrl}/api/search`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(finalRequestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch search results');
      }

      const data = await response.json();
      console.log("Search Results:", data);

      navigate('/search-results', { state: { results: data.results || [], filters: updatedFilters } });
    } catch (error) {
      console.error('Error fetching search results:', error);
      alert(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <nav className="bg-white shadow-md py-4 px-6">
        <div className="container mx-auto flex justify-between items-center">
          <a href="/" className="text-blue-600 text-3xl md:text-4xl lg:text-5xl font-bold">
            HomeLens.ai
          </a>
          <div className="flex space-x-4">
            <a href="/" className="text-gray-600 hover:text-primary transition-colors">
              Home
            </a>
            <a href="/search" className="text-gray-600 hover:text-primary transition-colors">
              Search
            </a>
          </div>
        </div>
      </nav>

      <div className="bg-hero-gradient bg-cover flex flex-col justify-center items-center text-center px-4 pt-12 md:pt-20">
        <h1 className="text-3xl md:text-5xl font-poppins font-extrabold text-secondary tracking-wide">
          Welcome to HomeLens.ai
        </h1>
        <p className="text-md md:text-lg font-light text-gray-600 italic mt-2">
          Vision-Driven Home Search
        </p>

        <div className="bg-white shadow-card p-6 rounded-2xl max-w-4xl w-full mt-6">
          {isLoading ? (
            <div className="text-gray-600">Loading...</div>
          ) : (
            <SearchBar
              filters={filters}
              setFilters={setFilters}
              onSearch={handleSearch}
              eulaAccepted={eulaAccepted}
              setEulaAccepted={setEulaAccepted}
            />
          )}
        </div>
      </div>

      <footer className="bg-gray-100 py-6 shadow-inner mt-auto">
        <div className="container mx-auto text-center text-gray-500 space-y-4">
          <p>© 2024 Mark Gores. All rights reserved.</p>
          <p className="text-sm">
            Listing data provided by MLS Grid. All information is deemed reliable but not guaranteed and should be independently verified.
          </p>
          <p className="text-sm">Courtesy of RE/MAX Advantage Plus.</p>
          <div className="flex justify-center space-x-4">
            <img
              src="/assets/logos/NorthstarMLStransparent.png"
              alt="MLS Grid Logo"
              className="h-8"
            />
            <img
              src="/assets/logos/2017ADVPLUS_GRADIENT_GRAYSCALE.png"
              alt="Broker Logo"
              className="h-8"
            />
          </div>
          <p className="text-sm">
            Mark Gores | Contact: (612) 201-5447 | License #20486494
          </p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;