import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Slider from 'react-slick';

function PropertyDetailsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const property = location.state?.property;

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    if (!property) {
      console.error('Property data is missing! Redirecting...');
      navigate('/search-results');
    } else {
      console.log('Property Data:', property);
    }
  }, [property, navigate]);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  if (!property) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Loading property details...</p>
      </div>
    );
  }

  const listingDetails = property.listingDetails || {};
  const detailedListingDetails = property.detailedListingDetails || {};
  const foxyai = property.foxyai || {};

  // Extract fields
  const {
    StreetNumber,
    StreetName,
    StreetSuffix,
    City,
    StateOrProvince,
    PostalCode,
    ListPrice,
    BedroomsTotal,
    BathroomsTotalInteger,
    LotSizeArea,
    PropertyType,
    DaysOnMarket,
    YearBuilt,
    Media = [],
    PublicRemarks
  } = listingDetails;

  // Also consider listingDetails.price and detailedListingDetails for publicRemarks fallback
  const rawPrice = (listingDetails.price && listingDetails.price !== 'N/A')
    ? listingDetails.price
    : (ListPrice && ListPrice !== 'N/A' ? ListPrice : null);

  const displayPrice = (rawPrice && !isNaN(Number(rawPrice)))
    ? `$${Number(rawPrice).toLocaleString()}`
    : 'Not Disclosed';

  let publicRemarksText = PublicRemarks && PublicRemarks !== 'N/A'
    ? PublicRemarks
    : (detailedListingDetails.publicRemarks && detailedListingDetails.publicRemarks !== 'N/A'
      ? detailedListingDetails.publicRemarks
      : 'No remarks available.'
    );

  // Address construction
  // First try listingDetails.address if present and not N/A
  let address = listingDetails.address && listingDetails.address !== 'N/A'
    ? listingDetails.address
    : '';

  if (!address) {
    // If address not directly provided, construct it
    const addressParts = [StreetNumber, StreetName, StreetSuffix, City, StateOrProvince, PostalCode].filter(Boolean);
    address = addressParts.join(' ');
    // If still no meaningful address, try fallback to City/State only
    if (!address.trim()) {
      if (City && StateOrProvince) {
        address = `${City}, ${StateOrProvince}`;
      } else if (City) {
        address = City;
      } else {
        address = 'Location Not Available';
      }
    }
  }

  // Image handling
  const associatedImages = detailedListingDetails.associatedImages || [];
  let imageUrls = Array.from(new Set(associatedImages)).filter(Boolean);

  if (imageUrls.length === 0 && Array.isArray(Media) && Media.length > 0) {
    imageUrls = Media.map(m => m.MediaURL).filter(Boolean);
  }
  if (imageUrls.length === 0 && foxyai.imageUrl) {
    imageUrls.push(foxyai.imageUrl);
  }

  const mainSliderSettings = {
    asNavFor: nav2,
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const thumbnailSliderSettings = {
    asNavFor: nav1,
    dots: false,
    infinite: true,
    focusOnSelect: true,
    slidesToShow: Math.min(5, imageUrls.length),
    swipeToSlide: true,
  };

  const handleBackToResults = () => {
    if (location.state?.results) {
      navigate('/search-results', { state: { results: location.state.results } });
    } else {
      navigate('/search-results');
    }
  };

  // Cleanly display fields only if they have meaningful values
  const displayBedrooms = (BedroomsTotal && !isNaN(Number(BedroomsTotal))) ? BedroomsTotal : null;
  const displayBathrooms = (BathroomsTotalInteger && !isNaN(Number(BathroomsTotalInteger))) ? BathroomsTotalInteger : null;
  const displayLotSize = (LotSizeArea && !isNaN(Number(LotSizeArea)) && Number(LotSizeArea) > 0)
    ? `${Number(LotSizeArea).toLocaleString()} acres`
    : null;
  const displayYearBuilt = (YearBuilt && !isNaN(Number(YearBuilt))) ? YearBuilt : null;
  const displayDaysOnMarket = (DaysOnMarket && !isNaN(Number(DaysOnMarket))) ? DaysOnMarket : null;
  const displayPropertyType = (PropertyType && PropertyType !== 'N/A') ? PropertyType : null;
  const displayPostalCode = (PostalCode && PostalCode !== 'N/A') ? PostalCode : null;

  return (
    <div>
      <Navbar />
      <div className="container mx-auto my-8 px-4">
        <button
          className="mb-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          onClick={handleBackToResults}
        >
          Back to Search
        </button>
        <nav className="text-sm text-gray-600 mb-4">
          <span
            onClick={() => navigate('/')}
            className="hover:underline cursor-pointer text-blue-500"
          >
            Home
          </span> &gt;{' '}
          <span
            onClick={handleBackToResults}
            className="hover:underline cursor-pointer text-blue-500"
          >
            Search Results
          </span> &gt;{' '}
          <span className="text-gray-500">Property Details</span>
        </nav>

        <h1 className="text-2xl font-bold mb-4">{address}</h1>

        {imageUrls.length > 0 ? (
          <div className="w-full max-w-5xl mx-auto mb-8">
            <h2 className="text-xl font-semibold mb-2">Property Photos</h2>
            <Slider {...mainSliderSettings} ref={slider1Ref}>
              {imageUrls.map((url, index) => (
                <div key={index} className="w-full h-[400px]">
                  <img
                    src={url}
                    alt={`Property Image ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                    onError={(e) => (e.target.style.display = 'none')}
                  />
                </div>
              ))}
            </Slider>
            {imageUrls.length > 1 && (
              <Slider {...thumbnailSliderSettings} ref={slider2Ref} className="mt-4">
                {imageUrls.map((url, index) => (
                  <div key={index} className="px-1">
                    <img
                      src={url}
                      alt={`Thumbnail ${index + 1}`}
                      className="w-full h-[80px] object-cover rounded border"
                      onError={(e) => (e.target.style.display = 'none')}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        ) : (
          <p className="text-red-500 text-center mb-8">No additional photos available.</p>
        )}

        <div className="p-6 bg-gray-100 rounded shadow-md max-w-3xl mx-auto">
          <h2 className="text-xl font-semibold mb-4 text-center">Property Details</h2>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <p><strong>Price:</strong> {displayPrice}</p>
            {displayBedrooms && <p><strong>Bedrooms:</strong> {displayBedrooms}</p>}
            {displayBathrooms && <p><strong>Bathrooms:</strong> {displayBathrooms}</p>}
            {displayLotSize && <p><strong>Lot Size Area:</strong> {displayLotSize}</p>}
            {displayYearBuilt && <p><strong>Year Built:</strong> {displayYearBuilt}</p>}
            {displayPropertyType && <p><strong>Property Type:</strong> {displayPropertyType}</p>}
            {displayDaysOnMarket && <p><strong>Days on Market:</strong> {displayDaysOnMarket}</p>}
            {displayPostalCode && <p><strong>Postal Code:</strong> {displayPostalCode}</p>}
          </div>

          {publicRemarksText && publicRemarksText !== 'No remarks available.' && (
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Public Remarks</h3>
              <p className="whitespace-pre-line leading-relaxed">{publicRemarksText}</p>
            </div>
          )}

          {(publicRemarksText === 'No remarks available.') && (
            <p className="text-gray-500">No remarks available.</p>
          )}

          {typeof foxyai.confidence === 'number' && (
            <div className="mt-4 border-t pt-4">
              <h3 className="text-lg font-semibold mb-2">Intangible Features</h3>
              <p><strong>FoxyAI Confidence Score:</strong> {foxyai.confidence.toFixed(3)}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertyDetailsPage;