// SearchBar.js

import React, { useState } from "react";

function SearchBar({ filters, setFilters, eulaAccepted, setEulaAccepted, onSearch, isLoading }) {
  const [foxyaiQuery, setFoxyaiQuery] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!eulaAccepted) {
      alert("You must agree to the terms of the license agreement before proceeding.");
      return;
    }

    // Filter out empty strings from filters
    const queryParams = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== "")
    );

    const trimmedFoxyaiQuery = foxyaiQuery.trim();

    // If both filters and FoxyAI query are empty, show an alert
    if (Object.keys(queryParams).length === 0 && !trimmedFoxyaiQuery) {
      alert("Please provide at least one filter or a FoxyAI query.");
      return;
    }

    // Construct the request body as a single object
    const requestBody = {
      filters: queryParams,  // This will be {} if no filters were provided
      foxyaiQuery: trimmedFoxyaiQuery // "" if no FoxyAI query was provided
    };

    // Call onSearch with the single requestBody object
    onSearch(requestBody);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-lg rounded-lg p-6 md:p-8 grid grid-cols-1 gap-4 w-full md:w-2/3 mx-auto"
    >
      <input
        type="text"
        placeholder="City"
        value={filters.City || ""}
        onChange={(e) => setFilters({ ...filters, City: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />
      <input
        type="text"
        placeholder="Postal Code"
        value={filters.PostalCode || ""}
        onChange={(e) => setFilters({ ...filters, PostalCode: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />
      <input
        type="number"
        placeholder="Min Price (ListPrice)"
        value={filters.minPrice || ""}
        onChange={(e) => setFilters({ ...filters, minPrice: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />
      <input
        type="number"
        placeholder="Max Price (ListPrice)"
        value={filters.maxPrice || ""}
        onChange={(e) => setFilters({ ...filters, maxPrice: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />
      <select
        value={filters.PropertyType || ""}
        onChange={(e) => setFilters({ ...filters, PropertyType: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      >
        <option value="">Property Type</option>
        <option value="Residential">Residential</option>
        <option value="Commercial">Commercial</option>
        <option value="Land">Land</option>
      </select>
      <input
        type="number"
        placeholder="Bedrooms (BedroomsTotal)"
        value={filters.BedroomsTotal || ""}
        onChange={(e) => setFilters({ ...filters, BedroomsTotal: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />
      <input
        type="number"
        placeholder="Bathrooms (BathroomsTotalInteger)"
        value={filters.BathroomsTotalInteger || ""}
        onChange={(e) => setFilters({ ...filters, BathroomsTotalInteger: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />
      <input
        type="number"
        placeholder="Lot Size Area (Acres) - LotSizeArea"
        value={filters.LotSizeArea || ""}
        onChange={(e) => setFilters({ ...filters, LotSizeArea: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />
      <input
        type="number"
        placeholder="Max Days on Market (DaysOnMarket)"
        value={filters.DaysOnMarket || ""}
        onChange={(e) => setFilters({ ...filters, DaysOnMarket: e.target.value })}
        className="border rounded px-4 py-3 text-gray-700 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />

      <input
        type="text"
        placeholder="AI Vision (e.g. 'modern kitchen')"
        value={foxyaiQuery}
        onChange={(e) => setFoxyaiQuery(e.target.value)}
        className="border rounded px-4 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
      />

      <div className="col-span-full text-center">
        <button
          type="submit"
          disabled={isLoading}
          className="bg-button-gradient text-white px-10 py-4 text-lg rounded-2xl shadow-button hover:scale-105 hover:shadow-lg transform transition-transform duration-300 w-full md:w-auto"
        >
          {isLoading ? "Searching..." : "Search"}
        </button>
      </div>

      <div className="col-span-full mt-4 flex items-center justify-center">
        <input
          type="checkbox"
          checked={eulaAccepted}
          onChange={() => setEulaAccepted(!eulaAccepted)}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
        <span className="ml-2 text-sm text-gray-600 text-center">
          I have read and agree to the{" "}
          <a href="/eula" className="text-blue-600 underline hover:text-blue-800">
            terms of the license agreement
          </a>.
        </span>
      </div>
    </form>
  );
}

export default SearchBar;